//Конфиг списков  для тура

export const eighthStepContent = [
	{
		title: '«Создать график поверки»',
		content:
			'При нажатии на эту кнопку выбираете диапазон нужных дат и фильтров графика, после чего нажимаете «Выгрузить» в нужном формате.',
	},
	{
		title: '«Шаблоны»',
		content:
			'После проведенной настройки таблицы основного экрана под какую-либо задачу Вы можете сохранить эти настройки в шаблоне и дать нужное название. В следующий раз достаточно просто открыть «Список шаблонов» и выбрать необходимый.',
	},
	{
		title: '«Печать бирок»',
		content:
			'Достаточно выбрать в основной таблице нужные строки СИ, нажать на печать бирок  и отправить на печать. Если Вам нужны дополнительные настройки бирок, Вы можете найти их в разделе «Инструменты» или в окне перед печатью, нажав на нужную бирку правой кнопкой мыши.',
	},
	{
		title: '«Уведомления»',
		content:
			'В системе Вы можете настроить уведомления о приближающихся датах выполнения работ и указать электронную почту для получения уведомлений.',
	},
	{
		title: '«Выгрузить»',
		content:
			'При необходимости Вы можете сделать быструю выгрузку всех данных в нужном формате. ',
	},
];

export const fourStepContent = [
	{
		title: '«Избранное»',
		content: 'доступ к избранным СИ;',
	},
	{
		title: '«Просроченные»',
		content: 'подборка СИ, у которых просрочены даты выполнения работ;',
	},
	{
		title: '«Месяц»',
		content: 'подборка СИ по датам выполнения работ в выбранном месяце;',
	},
	{
		title: '«Поиск»',
		content: 'интеллектуальный поиск по всем полям.',
	},
];

export const thirdStepContent = [
	{
		title: '«Редактировать СИ»',
		content: 'после нажатия на кнопку, открывается карточка для редактирования/просмотра;',
	},
	{
		title: 'Метрологические работы»',
		content:
			'позволяет сразу перейти к разделу «Метрологические работы» в карточке и внести изменения;',
	},
	{
		title: '«Файлы»',
		content: 'возможность открыть вложенные файлы или прикрепить новые;',
	},
	{
		title: '«Добавить в избранное»',
		content: 'позволяет формировать, выделять цветом и закреплять избранные СИ;',
	},
	{
		title: '«Удалить СИ»',
		content:
			'Вы можете удалить выбранное СИ из списка. После удаления СИ возможно восстановить через запрос персональному менеджеру.',
	},
];
