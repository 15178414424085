export enum ColumnNames {
	NAME = 'Наименование',
	TYPE = 'Тип СИ',
	FACTORY_NUMBER = 'Заводской номер',
	INVENTORY_NUMBER = 'Инвентарный номер',
	DIVISION = 'Подразделение',
	VERIFICATION_DATE = 'Дата МР',
	DATE_OF_THE_NEXT_VERIFICATION = 'Дата следующих МР',
	TYPE_OF_WORK = 'Вид МР',
	CONDITION = 'Состояние',
	STATE_REGISTER = 'Госреестр',
	CERTIFICATE = 'Свидетельство',
	PRODUCTION_DATE = 'Дата производства',
	ORGANIZATION = 'Организация',
	ACCURACY_CLASS = 'Класс точности',
	MEASUREMENT_LIMIT = 'Предел измерения',
	SIZE = 'Размер бирки',
	NOTES = 'Примечания',
	VERIFICATION_INTERVAL = 'Интервал МР',
	VERIFICATION_CONTROL_STATE_REGISTER = 'Контроль поверки в госреестре',
	LOCATION = 'Местоположение СИ',
	RESPONSIBLE = 'Ответственный',
	SUITABILITY = 'Пригодность',
	FGIS_URL = 'Ссылка',
	ADDITIONAL_DATA = 'Доп. данные',
	METHODOLOGY = 'Методика',
	COST = 'Стоимость',
	STATUS = 'Статус обработки',
	ACTIONS = 'Действия',
	VIEW = 'Вид СИ',
	EDITED_BY = 'Редактор',
	ACTION = 'Действие',
	MODIFICATION_DATE = 'Дата изменения',
	MANUFACTURER = 'Завод-изготовитель',
	DATE_OF_COMISSIONING = 'Дата ввода в эксплуатацию',
	INSTRUMENT_CERTIFICATE = 'Аттестат',
	SNILS = 'СНИЛС',
}
