import { IGroup } from './group';

import { Role } from 'constant/role';

export interface IUserPermission {
	[key: string]: boolean;
}

export enum GroupType {
	TRIAL = 'trial',
	CLIENT = 'client',
}

export interface IUserDataResponse {
	id: string;
	role: Role;
	groupType: GroupType;
	groupInfo: IGroup;
	permissions: IUserPermission[];
}

export interface IUserState {
	id: string | null;
	role: Role | null;
	groupType: GroupType;
	groupInfo: IGroup | null;
	userPermissions: IUserPermission[] | null;
}
