import { Navigate, useLocation } from 'react-router-dom';

import { selectUserRoles } from 'features/user/userSlice';
import { useAppSelector } from 'hooks/redux';
import { useAuth } from 'hooks/useAuth';

export function PrivateRoute({ children }: Record<'children', JSX.Element>) {
	const isAuth = useAuth();
	const location = useLocation();
	const { isReader } = useAppSelector(selectUserRoles);

	if (!isAuth) {
		return <Navigate to='/' replace state={{ returnUrl: location.pathname }} />;
	}

	if (location.pathname === '/arshin-integration' && isReader) {
		return <Navigate to='/home' replace state={{ returnUrl: location.pathname }} />;
	}

	return children;
}
