export const enum ArshinStatus {
	DONE = 'Готово',
	REWRITE = 'Перезаписано',
	STOP = 'Остановлен',
	FAILED_TO_RETRIEVE_DATA = 'Не удалось получить данные',
	AWAITING_SHIPMENT = 'Не обработано',
	PROCESS = 'В процессе',
	CANCELED = 'Проверка данных отменена пользователем',
	NOT_FOUND = 'Данные не обнаружены',
}
