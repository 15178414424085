import { useEffect, useState } from 'react';

import { selectGroupType } from 'features/user/userSlice';
import { useAppSelector } from 'hooks/redux';

/**
 * @package хук открывающий SSE канал
 * @props url => url для коннекта к каналу SSE
 * @props callBack => callBack которые будет принимать и обрабатывать ответ от SSE канала
 */

export const useServerSentEvent = (
	url: string,
	callBack: (event: MessageEvent) => void,
	disabled?: boolean
) => {
	const [isOpen, setIsOpen] = useState(false);

	const { isTrial } = useAppSelector(selectGroupType);

	useEffect(() => {
		if (isTrial) return;
		const eventSource = new EventSource(url, {
			withCredentials: true,
		});
		eventSource.onopen = () => {
			setIsOpen(true);
		};

		eventSource.onmessage = event => {
			callBack(event);
		};

		if (eventSource.onerror) {
			eventSource.close();
			setIsOpen(false);
		}
		return () => eventSource.close();
	}, [callBack, disabled, url, isTrial]);

	return isOpen;
};
