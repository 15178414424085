import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'app/store';
import { Role } from 'constant/role';
import type { IGroup } from 'types/group';
import { GroupType, IUserPermission, IUserState } from 'types/user';

const initialState: IUserState = {
	id: null,
	groupType: GroupType.TRIAL,
	groupInfo: null,
	role: null,
	userPermissions: null,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserId: (state, action: PayloadAction<string>) => {
			state.id = action.payload;
		},

		setPermissions: (state, action: PayloadAction<IGroup>) => {
			state.groupInfo = action.payload;
		},
		setGroupType: (state, action: PayloadAction<GroupType>) => {
			state.groupType = action.payload;
		},

		setRole: (state, action: PayloadAction<Role>) => {
			state.role = action.payload;
		},

		setUserPermissions: (state, action: PayloadAction<IUserPermission[]>) => {
			state.userPermissions = action.payload;
		},

		resetUserState: () => initialState,
	},
});

export const selectUserArshinStatus = (state: RootState) =>
	state.user.userPermissions?.[0]?.arshin ?? false;

export const selectGroupPermissions = createSelector(
	[(state: RootState) => state.user.groupInfo, (state: RootState) => state.user.userPermissions],
	(groupInfo, userPermissions) => ({
		maxRowsPerTable: groupInfo?.maxRowsPerTable ?? 0,
		maxNumberUserTemplates: groupInfo?.maxNumberUserTemplates ?? 0,
		maxNumberUsersAccountGroup: groupInfo?.maxNumberUsersAccountGroup ?? 0,
		isGroupAdministration: groupInfo?.hasGroupAdministration ?? false,
		isPrintLabel: groupInfo?.hasPrintingModule ?? false,
		isReceiveNotifications: groupInfo?.hasReceiveNotifications ?? false,
		isFileStorage: groupInfo?.hasFileStorage ?? false,
		maxSizePerRow: groupInfo?.maxSizePerRow ?? 0,
		groupHasArshin: groupInfo?.hasArshinIntegration ?? false,
	})
);

export const selectGroupType = createSelector(
	[(state: RootState) => state.user.groupType],
	groupType => ({
		isTrial: groupType === GroupType.TRIAL,
		isClient: groupType === GroupType.CLIENT,
	})
);
export const selectUserPermissions = (state: RootState) => state.user.userPermissions;

export const selectUserId = (state: RootState) => state.user.id;
export const selectUser = (state: RootState) => state.user.id;

export const selectUserRoles = createSelector([(state: RootState) => state.user.role], role => ({
	isReader: role === Role.USER_READER,
	isWriter: role === Role.USER_WRITER,
	isAdmin: role === Role.SUPER_ADMIN,
}));

export const userPath = userSlice.name;
export const userReducer = userSlice.reducer;
export const {
	setUserId,
	setPermissions,
	setRole,
	setUserPermissions,
	setGroupType,
	resetUserState,
} = userSlice.actions;
